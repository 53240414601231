import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql, withPrefix } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import { TitleWithLine, ButtonLink } from '../elements'

const PodcastsCarouselWrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 30px;
  @media (max-width: 575px) {
    padding-top: 20px;
  }
`

const PodcastsCarouselContainer = styled(Container)`
  padding-left: 0;
  padding-right: 0;
`

const PodcastsCarouselTitle = styled(TitleWithLine)`
  @media (max-width: 575px) {
    font-size: 30px;
  }
  span {
    color: ${(props) => props.theme.colorGreenLight};
  }
`

const PodcastsCarouselRowWrapper = styled(Row)`
  justify-content: center;
  padding-bottom: 50px;
  @media (max-width: 991px) {
    padding-bottom: 20px;
  }
  @media (max-width: 575px) {
    padding-bottom: 10px;
  }
`

const PodcastsCarouselDescWrapper = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  @media (max-width: 1399px) {
    font-size: 16px;
  }
  @media (min-width: 1200px) {
    padding-left: 34%;
    padding-right: 34%;
  }
  @media (min-width: 576px) and (max-width: 1199px) {
    padding-left: 20%;
    padding-right: 20%;
  }
`

const PodcastsCarouselSliderWrapper = styled(Col)`
  @media (max-width: 575px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const PodcastsCarouselSlider = styled(Slider)`
  margin-top: 40px;
  @media (max-width: 575px) {
    margin-top: 10px;
  }
  .slick-list {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 86.5%;
      right: 0;
      bottom: 0;
      filter: blur(20px);
      background-color: rgba(255, 255, 255, 0.4);
      pointer-events: none;
    }
  }
  .slick-slide {
    padding-top: 30px;
    padding-bottom: 10px;
    @media (min-width: 576px) and (max-width: 1199px) {
      padding-bottom: 15px;
    }
    &.slick-center {
      &.slick-current {
      }
    }
  }
  .slick-dots {
    bottom: -40px;
    z-index: 10;
    li {
      width: 25px;
      margin-left: 0;
      margin-right: 0;
      &.slick-active {
        button {
          &:before {
            background-color: ${(props) => props.theme.colorGreenLight};
          }
        }
      }
      button {
        width: 25px;
        &:before {
          content: '';
          width: 15px;
          height: 15px;
          border: 1px solid ${(props) => props.theme.colorGreenLight};
          border-radius: 50%;
        }
      }
    }
  }
`

const PodcastsCarouselSliderItem = styled.div`
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const PodcastsCarouselSliderItemWrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  align-content: space-between;
  text-decoration: none !important;
  border-radius: 10px;
  transform: translateY(0);
  transition: all 0.3s ease;
  @media (min-width: 576px) {
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.125);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.125);
  }
`

const PodcastsCarouselSliderItemImageWrapper = styled.div`
  width: 100%;
  @media (min-width: 1600px) and (max-width: 1900px) {
    padding-bottom: 30px;
  }
  @media (min-width: 1400px) and (max-width: 1599px) {
    padding-bottom: 50px;
  }
  @media (min-width: 1200px) and (max-width: 1399px) {
    padding-bottom: 50px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    padding-bottom: 70px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    padding-bottom: 10px;
  }
  @media (max-width: 370px) {
    padding-bottom: 20px;
  }
`

const PodcastsCarouselSliderItemDescWrapper = styled(Row)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
  padding-top: 15px;
  padding-bottom: 20px;
  z-index: 10;
  &:before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    right: -10%;
    bottom: -10%;
    background-color: rgba(255, 255, 255, 0.85);
    filter: blur(10px);
    pointer-events: none;
    z-index: 5;
  }
`

const PodcastsCarouselSliderItemDesc = styled(Col)`
  position: relative;
  flex: 0 0 80%;
  max-width: 80%;
  line-height: 1;
  z-index: 15;
  @media (min-width: 768px) {
    padding-right: 10%;
  }
  p {
    color: ${(props) => props.theme.colorGreenLight};
    margin-bottom: 10px;
    &.name {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      line-clamp: 1;
      box-orient: vertical;
      background-color: ${(props) => props.theme.colorWhite};
      font-family: ${(props) => props.theme.fontBNPPSansCondensed};
      font-size: 20px;
      font-weight: 400;
      text-transform: uppercase;
      padding-top: 6px;
      padding-left: 5px;
      padding-bottom: 3px;
      margin-bottom: -3px;
      @media (min-width: 1200px) and (max-width: 1399px) {
        font-size: 18px;
      }
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 16px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 16px;
      }
      @media (max-width: 575px) {
        font-size: 18px;
      }
    }
    &.title {
      box-orient: vertical;
      font-family: ${(props) => props.theme.fontBNPPSansCondensed};
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 15px;
      @media (min-width: 1537px) {
        height: 70px;
      }
      @media (min-width: 1400px) and (max-width: 1536px) {
        font-size: 20px;
        height: 70px;
      }
      @media (min-width: 1200px) and (max-width: 1399px) {
        height: 65px;
        font-size: 18px;
      }
      @media (min-width: 1095px) and (max-width: 1199px) {
        height: 60px;
        font-size: 16px;
      }
      @media (min-width: 992px) and (max-width: 1094px) {
        height: 60px;
        font-size: 15px;
      }
      @media (min-width: 768px) and (max-width: 991px) {
        height: 55px;
        font-size: 18px;
        margin-bottom: 5px;
      }
      @media (max-width: 575px) {
        height: 65px;
        font-size: 22px;
        margin-bottom: 5px;
      }
    }
  }
  audio {
    width: 100%;
    height: 48px;
    @media (min-width: 1200px) and (max-width: 1399px) {
      height: 42px;
    }
    @media (min-width: 992px) and (max-width: 1199px) {
      height: 39px;
    }
    @media (min-width: 768px) and (max-width: 991px) {
      height: 42px;
    }
    @media (min-width: 576px) and (max-width: 767px) {
      height: 48px;
    }
    @media (max-width: 575px) {
      height: 46px;
    }
  }
`

const PodcastsCarouselSliderItemPhoto = styled(Col)`
  position: relative;
  display: flex;
  flex: 0 0 20%;
  max-width: 20%;
  align-items: center;
  z-index: 15;
`

const PodcastsCarouselSliderItemPhotoWrapper = styled.div`
  width: 100%;
`

const PodcastsCarouselSliderItemButton = styled(ButtonLink)`
  background-color: #c0c5c4;
  border-radius: 0;
  outline: none !important;
  box-shadow: none !important;
  @media (min-width: 1200px) and (max-width: 1399px) {
    font-size: 20px;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 18px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 20px;
  }
`

const PodcastsCarousel = (props) => {
  const [activeFile, setActiveFile] = useState()
  const AudioPlayerRef = useRef()
  const SliderRef = useRef()

  const data = useStaticQuery(graphql`
    query {
      podcastImage1: file(
        relativePath: {
          eq: "parent/podcasts/jak-mowic-o-pieniadzach-i-nie-nudzic.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto1: file(
        relativePath: { eq: "parent/podcasts/expertPhoto3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage2: file(
        relativePath: {
          eq: "parent/podcasts/Czy-na-kieszonkowe-trzeba-zasluzyc.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto2: file(
        relativePath: { eq: "parent/podcasts/expertPhoto5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage3: file(
        relativePath: {
          eq: "parent/podcasts/Co-gdy-kieszonkowe-znika-w-jeden-dzien.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto3: file(
        relativePath: { eq: "parent/podcasts/expertPhoto3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage4: file(
        relativePath: {
          eq: "parent/podcasts/gdy-dziadkowie-rozpieszczaja-dzieci.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto4: file(
        relativePath: { eq: "parent/podcasts/expertPhoto5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage5: file(
        relativePath: { eq: "parent/podcasts/jak-stawiac-granice.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto5: file(
        relativePath: { eq: "parent/podcasts/expertPhoto3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage6: file(
        relativePath: {
          eq: "parent/podcasts/jak-nie-wychowac-materialisty.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto6: file(
        relativePath: { eq: "parent/podcasts/expertPhoto2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage7: file(
        relativePath: {
          eq: "parent/podcasts/co-gdy-inne-dzieci-maja-wyzze-kiesoznkowe.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto7: file(
        relativePath: { eq: "parent/podcasts/expertPhoto1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage8: file(
        relativePath: {
          eq: "parent/podcasts/co-lepsze-kieszonkowe-w-gotowce-czy-bezgotowkowe.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto8: file(
        relativePath: { eq: "parent/podcasts/expertPhoto4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage9: file(
        relativePath: {
          eq: "parent/podcasts/co-gdy-mnie-nie-stac-na-kieszonkowe.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto9: file(
        relativePath: { eq: "parent/podcasts/expertPhoto1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage10: file(
        relativePath: {
          eq: "parent/podcasts/czy-pozyczanie-pieniedzy-od-dziecka-jest-ok.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto10: file(
        relativePath: { eq: "parent/podcasts/expertPhoto3.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      podcastImage11: file(
        relativePath: {
          eq: "parent/podcasts/dlaczego-ta-trudno-rozmawiac-o-pieniadzach.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto11: file(
        relativePath: { eq: "parent/podcasts/expertPhoto6.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      podcastImage12: file(
        relativePath: {
          eq: "parent/podcasts/jak-ustalic-wysokosc-kiesoznkowego.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      expertPhoto12: file(
        relativePath: { eq: "parent/podcasts/expertPhoto5.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 56) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const [activeSlide, setActiveSlide] = useState(0)

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    centerMode: false,
    centerPadding: '14.5%',
    autoplay: false,
    autoplaySpeed: 15000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const experts = [
    {
      expertName: 'Natalia Tur',
      title: `Jak mówić o pieniądzach i nie przynudzać?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC01.mp3',
    },
    {
      expertName: 'Maciej Samcik',
      title: `Czy na kieszonkowe trzeba zasłużyć?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC02.mp3',
    },
    {
      expertName: 'Natalia Tur',
      title: `Co robić, gdy młoda szasta kasą?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC03.mp3',
    },
    {
      expertName: 'Maciej Samcik',
      title: `Jak uczyć oszczędzania, gdy dziadkowie rozpieszczają dzieci?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC04.mp3',
    },
    {
      expertName: 'Natalia Tur',
      title: `Jak zapanować nad chęcią „chcę to teraz”?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC05.mp3',
    },
    {
      expertName: 'Tatiana Mindewicz-Puacz',
      title: `Jak nie wychować materialisty?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC06.mp3',
    },

    {
      expertName: 'Monika Sajkowska',
      title: `Co, gdy inne dzieci mają wyższe kieszonkowe?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC07.mp3',
    },
    {
      expertName: 'Aleksander Naganowski',
      title: `Co lepsze – kieszonkowe w gotówce czy bezgotówkowe?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC08.mp3',
    },
    {
      expertName: 'Monika Sajkowska',
      title: `Co, gdy mnie nie stać na kieszonkowe?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC09.mp3',
    },
    {
      expertName: 'Natalia Tur',
      title: `Czy pożyczanie pieniędzy od dziecka jest OK?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC10.mp3',
    },
    {
      expertName: 'Grzegorz Markowski',
      title: `Dlaczego nam trudno rozmawiać o pieniądzach?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC11.mp3',
    },
    {
      expertName: 'Maciej Samcik',
      title: `Jak ustalić wysokość kieszonkowego?`,
      podcastLink: '/parent-mp3/BNP KIESZONKOWE 20220318 SC12.mp3',
    },
  ]

  const handleFileClick = (idx) => {
    setActiveFile(idx)
  }

  const handlePlayerPause = (e) => {
    AudioPlayerRef.current.pause()
  }

  const handlePlayerLoad = (e) => {
    AudioPlayerRef.current.play()
  }

  const changeActiveSlide = (number) => {
    setActiveSlide(number)
  }

  const expertCards = (activeSlide) => {
    return experts.map((expert, idx) => {
      return (
        <PodcastsCarouselSliderItem
          key={idx}
          onClick={() => changeActiveSlide(idx)}
        >
          <PodcastsCarouselSliderItemWrapper>
            <PodcastsCarouselSliderItemImageWrapper
              index={idx}
              current={activeSlide}
              className="image"
            >
              <Img
                fluid={data[`podcastImage${idx + 1}`].childImageSharp.fluid}
                className="expertImage"
                alt={expert.title.replace(/<\/?[a-zA-Z]+>/gi, '')}
              />
            </PodcastsCarouselSliderItemImageWrapper>
            <PodcastsCarouselSliderItemDescWrapper>
              <PodcastsCarouselSliderItemPhoto>
                <PodcastsCarouselSliderItemPhotoWrapper>
                  <Img
                    fluid={data[`expertPhoto${idx + 1}`].childImageSharp.fluid}
                    className="expertPhoto"
                    alt={expert.expertName.replace(/<\/?[a-zA-Z]+>/gi, '')}
                  />
                </PodcastsCarouselSliderItemPhotoWrapper>
              </PodcastsCarouselSliderItemPhoto>
              <PodcastsCarouselSliderItemDesc>
                <p className="name">{expert.expertName}</p>
                <p className="title">{expert.title}</p>

                {activeFile !== idx && (
                  <PodcastsCarouselSliderItemButton
                    as="button"
                    className="btn"
                    onClick={() => handleFileClick(idx)}
                  >
                    Posłuchaj
                  </PodcastsCarouselSliderItemButton>
                )}

                {activeFile === idx && (
                  <audio
                    controls
                    ref={AudioPlayerRef}
                    onPause={handlePlayerPause}
                    onLoadedData={handlePlayerLoad}
                  >
                    <source
                      src={withPrefix(experts[idx].podcastLink)}
                      type="audio/mpeg"
                    />
                  </audio>
                )}
              </PodcastsCarouselSliderItemDesc>
            </PodcastsCarouselSliderItemDescWrapper>
          </PodcastsCarouselSliderItemWrapper>
        </PodcastsCarouselSliderItem>
      )
    })
  }

  return (
    <PodcastsCarouselWrapper id="advices">
      <PodcastsCarouselContainer fluid={true}>
        <Row>
          <Col>
            <PodcastsCarouselTitle color="#019662" id="chapter">
              Wszystko, co chcielibyście wiedzieć
              <br />
              <span>O KIESZONKOWYM</span>
            </PodcastsCarouselTitle>
            <PodcastsCarouselDescWrapper>
              <p>
                Temat kieszonkowego budzi wiele pytań. Poniżej znajdziesz
                odpowiedzi na{'\u00A0'}12{'\u00A0'}z{'\u00A0'}nich, tych
                najbardziej nurtujących rodziców. Kliknij w{'\u00A0'}kafel i
                {'\u00A0'}
                posłuchaj wypowiedzi eksperta.
              </p>
            </PodcastsCarouselDescWrapper>
          </Col>
        </Row>
        <PodcastsCarouselRowWrapper>
          <PodcastsCarouselSliderWrapper xs={12}>
            <PodcastsCarouselSlider {...sliderSettings} ref={SliderRef}>
              {expertCards(activeSlide)}
            </PodcastsCarouselSlider>
          </PodcastsCarouselSliderWrapper>
        </PodcastsCarouselRowWrapper>
      </PodcastsCarouselContainer>
    </PodcastsCarouselWrapper>
  )
}

export default PodcastsCarousel
