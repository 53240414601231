import React from 'react'
import { Link } from 'react-scroll'
import styled from 'styled-components'
import { Container, Row, Col } from 'reactstrap'

import welcomeDesktopBG from '../../images/parent/welcome-bg.svg'
import welcomeMobileBG from '../../images/parent/welcome-bg_mobile.svg'

const WelcomeWrapper = styled.div`
  background-image: url(${welcomeDesktopBG});
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-top: 30px;
  padding-top: 80px;
  padding-bottom: 140px;
  @media (max-width: 575px) {
    background-image: url(${welcomeMobileBG});
    background-position: bottom center;
    background-size: 100% auto;
    padding-top: 50px;
    padding-bottom: 450px;
    margin-top: 0;
  }
  @media (max-width: 420px) {
    padding-bottom: 420px;
  }
  @media (max-width: 400px) {
    padding-bottom: 400px;
  }
  @media (max-width: 340px) {
    padding-bottom: 380px;
  }
`

const WelcomeTitle = styled.h1`
  position: relative;
  color: ${(props) => props.theme.colorGreenLight};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 50px;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 40px;
  @media (max-width: 767px) {
    font-size: 30px;
    margin-bottom: 50px;
  }
  @media (max-width: 575px) {
    font-size: 45px;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -20px;
    display: block;
    width: 10%;
    height: 4px;
    background-color: #5dc66a;
    @media (max-width: 767px) {
      bottom: -15px;
      width: 25%;
      height: 3px;
    }
    @media (max-width: 575px) {
      height: 3px;
    }
  }
`

const WelcomeDescWrapper = styled(Col)`
  line-height: 1;
  strong {
    font-size: 24px;
    font-weight: 400;
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 15px;
    @media (max-width: 767px) {
      font-size: 14px;
      padding-right: 40%;
    }
    @media (max-width: 575px) {
      padding-right: 15px;
    }
    br {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
`

export const WelcomeDescLink = styled(Link)`
  cursor: pointer;
  position: relative;
  display: inline-block;
  background-color: ${(props) => props.theme.colorGreenLight};
  color: ${(props) => props.theme.colorWhite};
  font-family: ${(props) => props.theme.fontBNPPSansCondensed};
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 45px;
  padding-right: 45px;
  margin-top: 25px;
  transition: all 0.2s ease;
  @media (max-width: 575px) {
    width: 100%;
    font-size: 23px;
    text-align: center;
    margin-bottom: 0;
  }
  &:hover,
  &:focus {
    color: ${(props) => props.theme.colorWhite};
    text-decoration: none;
    &:before {
      right: 20px;
    }
  }
  &:before {
    content: '';
    position: absolute;
    right: 25px;
    bottom: 12px;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 7px solid ${(props) => props.theme.colorWhite};
    transition: all 0.2s ease;
  }
`

const Welcome = (props) => {
  return (
    <WelcomeWrapper id="top">
      <Container>
        <Row>
          <Col xs={12}>
            <WelcomeTitle>Witaj w Bazie Wiedzy Rodzica!</WelcomeTitle>
          </Col>
          <WelcomeDescWrapper xs={12} md={6}>
            <p>
              <strong>
                Rodzic uzbrojony w{'\u00A0'}wiedzę
                <br /> to{'\u00A0'}najlepszy rodzic dla Samodzielniaka!
              </strong>
            </p>
            <p>
              Mamy nadzieję, że{'\u00A0'}zebrane tu{'\u00A0'}artykuły, podcasty,
              materiał wideo
              <br />z{'\u00A0'}warsztatów oraz ciekawostki okażą się pomocne nie
              tylko
              <br />w{'\u00A0'}codziennych rozmowach z{'\u00A0'}dzieckiem, ale
              też przy
              <br />
              podejmowaniu decyzji finansowych.{' '}
            </p>
          </WelcomeDescWrapper>
        </Row>
      </Container>
    </WelcomeWrapper>
  )
}

export default Welcome
