import React from 'react'
import styled from 'styled-components'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Welcome from '../../components/parent/welcome-old'
import Links from '../../components/parent/links'
import PodcastCarousel from '../../components/parent/podcasts-carousel'
import Articles from '../../components/parent/articles'
import Curiosity from '../../components/parent/curiosity'
import ParentsTools from '../../components/parent/parents-tools'
import Experts from '../../components/parent/experts'
import Workshops from '../../components/parent/workshops'
import Podcasts from '../../components/parent/podcasts'
import ParentArea from '../../components/parent/parent-area'
import TopScroll from '../../components/parent/top-scroll'

const IndexPageWrapper = styled.div`
  overflow: hidden;
  /* background-color: #f8f8f8; */
  background: rgba(184, 225, 210, 1);
  background: linear-gradient(
    -50deg,
    rgba(184, 225, 210, 1) 0%,
    rgba(255, 255, 255, 1) 90%
  );
  @media (max-width: 575px) {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      70deg,
      rgba(255, 255, 255, 1) 40%,
      rgba(184, 225, 210, 1) 60%
    );
  }
`

const IndexPageInnerWrapper = styled.div`
  background: rgb(244, 242, 247);
  background: linear-gradient(
    50deg,
    rgba(244, 242, 247, 1) 0%,
    rgba(255, 255, 255, 0.35) 60%
  );
  @media (max-width: 575px) {
    background: linear-gradient(
      50deg,
      rgba(244, 242, 247, 1) 0%,
      rgba(255, 255, 255, 0.35) 20%
    );
  }
`

const IndexPage = ({ location }) => {
  const linksList = [
    {
      name: 'Porady',
      link: 'advices',
      offset: -100,
    },
    {
      name: 'Quiz',
      link: '/strefa-rodzica/quiz',
      external: true,
    },
    {
      name: 'Artykuły',
      link: 'articles',
      offset: -100,
    },
    {
      name: 'Podcasty',
      link: 'podcasts',
      offset: -80,
    },
    {
      name: 'Ciekawostki',
      link: 'curiosity',
      offset: -50,
    },
    {
      name: 'Narzędzia',
      link: 'narzedzia',
      offset: -150,
    },
    {
      name: 'Warsztaty',
      link: 'warsztaty',
      offset: -150,
    },
  ]

  return (
    <>
      <SEO title="Baza wiedzy - Strefa rodzica" />
      <IndexPageWrapper>
        <IndexPageInnerWrapper>
          <Layout location={location}>
            <Welcome />
            <Links links={linksList} />
            <PodcastCarousel />
            <Articles />
            <Podcasts />
            <Curiosity />
            <ParentsTools />
            <Experts />
            <Workshops />
            <ParentArea page="parent" />
            <TopScroll />
          </Layout>
        </IndexPageInnerWrapper>
      </IndexPageWrapper>
    </>
  )
}

export default IndexPage
